import { graphql } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout";
import CTADemo from "../components/sections/cta-demo";
import SEO from "../components/seo";

export default function BlogPost({ data }) {
  const post = data.wpPost;
  const blogPostUrl = typeof window !== "undefined" ? window.location.href : "";
  const linkedinUrl = `https://www.linkedin.com/shareArticle?url=${blogPostUrl}&title=${post.title}&summary=${post.excerpt}&source=Coeus.chat`;
  const twitterUrl = `https://twitter.com/intent/tweet?url=${blogPostUrl}&text=${post.title}`;
  const facebookUrl = `https://www.facebook.com/sharer.php?u=${blogPostUrl}&title=${post.title}`;
  const emailUrl = `mailto:?subject=${post.title}&body=${blogPostUrl}`;

  return (
    <Layout>
      <article
        itemScope
        itemType="http://schema.org/Article"
        className="mx-auto max-w-4xl px-10 lg:px-0 py-10 space-y-8 font-openSans"
      >
        <h1 itemProp="headline" className="text-4xl lg:text-5xl font-bold">
          {post.title}
        </h1>
        {post.featuredImage && (
          <GatsbyImage
            image={
              post.featuredImage.node.localFile.childImageSharp.gatsbyImageData
            }
            alt={post.featuredImage.node.altText}
            className="rounded-xl"
          />
        )}
        <p className="text-gray-500">{post.date}</p>
        <section
          itemProp="articleBody"
          className="prose lg:prose-lg max-w-full text-gray-900 w-full font-normal"
          dangerouslySetInnerHTML={{ __html: post.content }}
        />
        <div className="block">
          <div className="space-y-2 mt-10">
            <h2 className="text-xl font-medium text-right">
              Share on social media
            </h2>
            <div className="flex space-x-2 justify-end">
              <a
                href={emailUrl}
                className="text-white bg-gray-700 hover:bg-gray-500 transition-colors rounded-full h-8 w-8 flex items-center justify-center"
                target="_blank"
              >
                <StaticImage
                  src="../assets/icon-email.svg"
                  placeholder="tracedSVG"
                  loading="lazy"
                  width={16}
                  height={15}
                  alt="email icon"
                />
              </a>
              
              <a
                href={linkedinUrl}
                className="bg-gray-700 hover:bg-gray-500 transition-colors rounded-full h-8 w-8 flex items-center justify-center"
                target="_blank"
              >
                <StaticImage
                  src="../assets/icon-linkedin.svg"
                  placeholder="tracedSVG"
                  loading="lazy"
                  width={16}
                  height={15}
                  alt="linkedin icon"
                />
              </a>

              <a
                href={twitterUrl}
                className="bg-gray-700 hover:bg-gray-500 transition-colors rounded-full h-8 w-8 flex items-center justify-center"
                target="_blank"
              >
                <StaticImage
                  src="../assets/icon-twitter.svg"
                  placeholder="tracedSVG"
                  loading="lazy"
                  width={16}
                  height={14}
                  alt="twitter icon"
                />
              </a>

              <a
                href={facebookUrl}
                className="bg-gray-700 hover:bg-gray-500 transition-colors rounded-full h-8 w-8 flex items-center justify-center"
                target="_blank"
              >
                <StaticImage
                  src="../assets/icon-facebook.svg"
                  placeholder="tracedSVG"
                  loading="lazy"
                  width={8}
                  height={18}
                  alt="facebook icon"
                />
              </a>
            </div>
          </div>
        </div>
      </article>

      <CTADemo />
    </Layout>
  );
}
export const query = graphql`
  query ($slug: String!) {
    wpPost(slug: { eq: $slug }) {
      title
      content
      excerpt
      date(formatString: "MMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
              )
            }
          }
        }
      }
    }
  }
`;

export const Head = ({ location, params, data, pageContext }) => {
  const post = data.wpPost;
  const description = post.excerpt
    ? post.excerpt.replace(/(<([^>]+)>)/gi, "")
    : null;

  return <SEO title={post.title} description={description}></SEO>;
};
